import * as Repo from '@/repository/auth.js';
import jwtDecode from 'jwt-decode';
import { backendUrl, KeycloakInfo } from '@/util/env.js';
import { setToken } from '@/util/jwt-cache.js';
import Keycloak from 'Keycloak';
import EventBus from '@/util/eventbus.js';

const BML_DOMAIN = backendUrl.substr(backendUrl.lastIndexOf('/') + 1);

const keycloak = new Keycloak(KeycloakInfo);

let initPromise;
let refreshTimer;
let isInit = false;
const state = {
	main: {
		token: null,
		account: null,
		details: {}
	},
	ssoToken: null,
	ssoRefreshToken: null,
	ssoRefreshTokenParsed: null,
	ssoParsed: null,
	keycloakAccountUrl: null
};


const getters = {

	isLoggedIn: (state) => {
		return !!state.main.token;
	},

	getCurrentToken: (state) => {
		return state.main.token;
	},

	getIsExpired: (state, getters) => {
		return !getters.isLoggedIn;
	},

	isSsoTokenExpired: (state) => {
		return !state.ssoParsed;
	},

	hasAccountSelected: (state) => {
		return state.main.account !== null;
	},

	getValidTokenForAccount: (state) => (accountId) => {
		if (state.main.account === accountId) {
			return state.main.token;
		}
		return null;
	},

	getKeycloakAccountUrl: (state) => {
		return state.keycloakAccountUrl;
	},

	hasSsoLogin: (state) => {
		return state.ssoParsed;
	},

	currentAccountCompany: (state) =>{
		if (state.main.details && state.main.details.staticData && 'companyLink' in state.main.details.staticData) {
			return state.main.details.staticData.companyLink;
		} else {
			return null;
			// return '59dfb1a5-aa13-42e1-8a8e-cef1ddade200';	//@TODO remove debugging
		}
	},

	currentAccountInstitution: (state, getters, rootState, rootGetters) => {
		const institutions = rootGetters['auth/org/institutionsByCurrentAccount'];
		let instis = institutions.filter((e) =>
			e.data.users?.includes(state.main.account),
		);
		return instis.map((e) => e.id);
	},

	accountId(state) {
		return state.main.account;
	},

	accountDetails(state) {
		return state.main.details;
	}

};

const actions = {


	/**
	 * should be called once at the start
	 **/
	async initApp({ dispatch, state, commit }) {
		if (!initPromise) {
			let opts = {};
			if (state.ssoToken && state.ssoRefreshToken) {
				opts.token = opts.idToken = state.ssoToken;
				opts.refreshToken = state.ssoRefreshToken;
				opts.onLoad = 'check-sso';
			}
			initPromise = keycloak.init(opts);
		}
		let auth = await initPromise;
		if (isInit) return;
		isInit = true;
		// console.log('init', keycloak, auth);
		if (auth) {
			if (state.ssoToken && state.ssoRefreshToken) {
				try {
					await keycloak.updateToken(65);
				} catch (e) {
					console.log('failed to update initial token', e);
					commit('logout');
					return;
				}
			}
			await dispatch('authenticate');
		} else {
			commit('logout');
		}
		if (!refreshTimer) {
			refreshTimer = setInterval(async() => {
				await dispatch('refreshTokenAndLogin');
			}, 1000 * 30);
		}
	},

	/**
	 * call to log in the user
	 **/
	async authenticate({ commit, dispatch, state }) {
		console.log('authenticate', keycloak);
		if (!keycloak.token) {
			await keycloak.login({});
		}
		commit('setSsoToken', keycloak);
		const url = await keycloak.createAccountUrl();
		commit('setKeycloakAccountUrl', url);
		let data = await Repo.loginToDomain(state.ssoToken, state.main.account);
		commit('login', data.jwt);
		await dispatch('switchToFirstAvailableAccount');
	},

	/**
	 * call to log out the user
	 **/
	async logout({ commit }) {
		commit('logout');
		await keycloak.logout();
	},

	async switchAccountAndLogin({ state, commit, dispatch, getters }, accountId) {
		if (state.main.account && accountId === state.main.account) return;
		EventBus.emit('product.cache.clear');
		let existing = getters.getValidTokenForAccount(accountId);
		if (existing) {
			commit('login', existing);
		} else {
			let data = await Repo.switchAccount(accountId);
			if (!data) throw new Error('Could not switch account.');
			commit('login', data.jwt);
		}
		await dispatch('auth/user/loadAccountData', null, { root: true });
		const account = getters['auth/user/getCurrentAccount'];
		commit('setAccountData', account);
		await dispatch('auth/user/updateUserEmail', {}, { root: true });
		window.location.reload();
	},

	async switchToFirstAvailableAccount({ dispatch, getters, rootGetters, commit }) {
		await dispatch('auth/user/loadAccounts', { ifEmpty: true }, { root: true });
		let available = rootGetters['auth/user/getAccounts']();
		if (available.length > 0) {
			available = available.filter(account => account.data.active && account.data.registrationOptionName === 'editor');
			if (!getters.hasAccountSelected) {
				//2 account
				await dispatch('switchAccountAndLogin', available[0].id);
			} else {
				//1 account
				await dispatch('auth/user/loadAccountData', null, { root: true });
				await dispatch('auth/user/updateUserEmail', {}, { root: true });
			}
			const account = getters['auth/user/getCurrentAccount'];
			commit('setAccountData', account);
		} else {
			//0 accounts
			console.warn('no accounts to switch to');
		}
	},

	async refreshTokenAndLogin({ commit, dispatch, getters, state }) {
		if (!isInit || !initPromise) {
			return;
		}

		if (!state.ssoRefreshTokenParsed || state.ssoRefreshTokenParsed.exp < ~~(Date.now()/1000)) {
			if (getters.isLoggedIn) await dispatch('logout');
			return;
		}
		try {
			let refreshed = await keycloak.updateToken(65);
			if (refreshed) {
				commit('setSsoToken', keycloak);
				let data = await Repo.loginToDomain(state.ssoToken, state.main.account);
				commit('login', data.jwt);
			}
		} catch (e) {
			console.log('refresh token failed.');
		}
	}

};

const mutations = {

	login(state, jwt) {
		let { aud, sub, exp } = jwtDecode(jwt);
		if (aud !== BML_DOMAIN) {
			throw new Error(`token expected for ${BML_DOMAIN} but received for ${aud}`);
		}
		let expDate = new Date(exp * 1000);
		if (expDate < new Date()) {
			throw new Error('token is expired');
		}
		if (!sub) sub = null;
		state.main.token = jwt;
		state.main.account = sub;
		setToken(jwt);
		// EventHub.$emit('auth.login');
	},

	logout(state) {
		state.main.token = null;
		state.main.account = null;
		state.ssoToken = null;
		state.ssoParsed = null;
		state.keycloakAccountUrl = null;
		state.ssoRefreshTokenParsed = null;
		setToken(null);
	},

	setSsoToken(state, { token, tokenParsed, refreshToken, refreshTokenParsed }) {
		state.ssoToken = token;
		state.ssoParsed = tokenParsed;
		state.ssoRefreshToken = refreshToken;
		state.ssoRefreshTokenParsed = refreshTokenParsed;
	},

	setKeycloakAccountUrl(state, url) {
		state.keycloakAccountUrl = url;
	},

	setAccountData(state, data) {
		state.main.details = data;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
