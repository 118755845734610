import Store from '@/store/main.js';
import Vue from 'vue';
import { toLocale } from '@/plugins/filters';

export function hasAnyKeyFilled(language) {
	if (!language || typeof language !== 'object') {
		return false;
	}

	const values = Object.values(language);
	return values.some(f => f.trim() !== '');
}

export function getProductTitle(p) {
	const unknown = { de_DE: 'Unbenannt' };
	if (!p || !p.data || !p.data.listData) {
		return unknown;
	}
	let lists = p.data.listData;
	let title = null;
	let key = 'basis-f1';

	const baseListId = Store.getters['list/baseDataList'].id;
	title = lists[baseListId][key];

	if (!toLocale(title).trim()) {
		if (toLocale(p.data.indexData[baseListId].name).trim()) {
			return p.data.indexData[baseListId].name;
		} else {
			for (let entry of Object.values(lists[baseListId][key])) {
				if (entry.trim()) {
					title = {};
					title[`${title[Store.state.base.selectedUiLanguage]}`] = entry;
					title[`${title[Store.state.base.fallbackLocale]}`] = entry;
					return title;
				}
			}
		}
		return unknown;
	}

	return title;
}

export function getFieldValue(valueObj, fieldKey, defaultValue) {
	if (!valueObj || valueObj === null || typeof valueObj !== 'object' || !(fieldKey in valueObj)) {
		return defaultValue;
	}

	return valueObj[fieldKey];
}

export function isFieldVisibleInList(field, listId) {
	if (!field.data) {
		return true;
	}

	const visibleInLists = field.data.visibleInLists;
	if (!visibleInLists || visibleInLists.length === 0) {
		return true;
	}

	return visibleInLists.includes(listId);
}


export function deepSet(node, parts, value) {
	let noChange = false;
	parts.forEach((p, i) => {
		if (!isNaN(p)) p = ~~p;
		if (i === parts.length - 1) {
			if (value === null) {
				if (!(p in node)) {
					noChange = true;
					return;
				}
				Vue.delete(node, p);
			} else {
				if (node[p] === value) {
					noChange = true;
					return;
				}
				Vue.set(node, p, value);
			}
		} else {
			if (!(p in node)) {
				let v = isNaN(parts[i+1]) ? {} : [];
				Vue.set(node, p, v);
			}
			node = node[p];
		}
	});
	return noChange;
}

export function deepGet(v, parts) {
	for (let i=0, ii=parts.length; i<ii; i++) {
		let p = parts[i];

		if (p in v) {
			v = v[p];
		} else {
			return null;
		}
	}
	if (typeof v !== 'undefined' && v !== null) {
		return v;
	} else {
		return null;
	}
}

export function getFieldByNode(node) {
	let id = node.fieldId;

	if (node.tableColumnId) {
		id = node.tableColumnId;
	}

	return Store.getters['columnset/fieldById'](id);
}

export function getFieldByPathOrTableColumnId(fieldId, tableColumnId) {
	return Store.getters['columnset/fieldByPath'](tableColumnId || fieldId, fieldId);
}

export function getTypeByField(field, useFilterType) {
	if (!field) {
		return 'textfield';
	}

	const type = useFilterType ? field.data.filterType || field.data.type : field.data.type;

	switch (type) {
		case 'table':
		case 'comment':
		case 'treeselect':
		case 'singleline-textfield':
		case 'multitext':
		case 'singleline-multitext': {
			return 'textfield';
		}
	}

	return type;
}

export function getSourceOptionsForField(field, tableRowId, includeNull) {
	if (!field || !field.data.type.includes('select')) {
		return [];
	}

	if (field.data.optionSource) {
		let s = Store.getters['product/getOptionsBySource'](field.data.optionSource);

		if (tableRowId && field.data.useParentSelection) {
			s = s.filter(f => f.parentRowId === tableRowId);
		}

		return s;
	}

	const options = field.data.options.map(f => {
		return {
			value: f.id,
			text: toLocale(f.title)
		};
	});

	if (includeNull) {
		options.unshift({
			value: '',
			text: '-'
		});
	}

	return options;
}

export function traverse(
	data ,
	visitor,
	opts,
) {
	const depthFirst = opts && opts.depthFirst;
	function run(layer, stack) {
		if (!depthFirst) visitor(layer, stack, data);
		if (Array.isArray(layer)) {
			layer.forEach((item, i) => {
				let ns = stack.slice(0);
				ns.push(i);
				run(item, ns);
			});
		} else if (typeof layer === 'object' && layer !== null) {
			Object.keys(layer).forEach((key) => {
				let val = layer[key];
				let ns = stack.slice(0);
				ns.push(key);
				run(val, ns);
			});
		}
		if (depthFirst) visitor(layer, stack, data);
	}
	run(data, []);
}